import React, {Component} from 'react';
import {
				BrowserRouter as Router,
				Route,
				Switch,
				Redirect
} from 'react-router-dom';
import {Provider} from 'react-redux';
import { CookiesProvider, withCookies } from 'react-cookie';
import Home from './pages/Home';
import Availability from './pages/Availability';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAgreement from './pages/TermsAgreement';
import Details from './pages/Details';
import store from './store';

class App extends Component {
				render() {
								return (
												<CookiesProvider>
																<Provider store={store}>
																				<Router>
																								<Switch>
																												<Route exact path="/" render={() => (<Home cookies={this.props.cookies}/>)}/>
																												<Route path="/beschikbaar" render={() => (<Availability cookies={this.props.cookies}/>)}/>
																												<Route path="/details" render={() => (<Details cookies={this.props.cookies}/>)}/>
																												<Route path="/privacy-policy" component={PrivacyPolicy} />
																												<Route path="/algemene-voorwaarden" component={TermsAgreement} />
																												<Redirect to="/"/>
																								</Switch>
																				</Router>
																</Provider>
												</CookiesProvider>
								);
				}
}

export default withCookies(App);
