import {PROFESSIONALS} from "../data";

export default function (state = {PROFESSIONALS}, action) {
				switch (action.type) {
								case "PROFESSIONALS_SELECTED": {
												return action.payload;
								}
								default:
												return state;
				}
}