import moment from 'moment';

const NextDay = moment(new Date()).add(1, 'days').format("dddd");

const NextWorkDay = () => {
				switch (NextDay) {
								case 'zaterdag' || 'Zaterdag' ||  'saturday' || 'Saturday':
												return moment(new Date()).add(3, 'days');
								case 'zondag' || 'Zondag' || 'sunday' || 'Sunday':
												return moment(new Date()).add(2, 'days');
								default:
												return moment(new Date()).add(1, 'days');
				}
}

export default NextWorkDay;