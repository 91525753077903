import {combineReducers} from "redux";
import packagesReducer from "./selected-packages";
import datesReducer from './selected-dates';
import optionsReducer from './selected-options';
import professionalsReducer from './selected-professionals';
import optionsPriceReducer from './options-price';
import totalPriceReducer from './total-price';

const rootReducer = combineReducers({
				selectedPackage: packagesReducer,
				selectedDate: datesReducer,
				selectedOptions: optionsReducer,
				selectedProfessionals: professionalsReducer,
				optionsPrice: optionsPriceReducer,
				totalPrice: totalPriceReducer
});

export default rootReducer;