import NextWorkDay from '../components/NextWorkDay';
import moment from 'moment'

export default function (state = {startDate: moment(NextWorkDay()), endDate: moment(NextWorkDay()), total: 1}, action) {
				switch (action.type) {
								case "DATE_SELECTED": {
												return action.payload;
								}
								default:
												return state;
				}
}