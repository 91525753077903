/*global $:true*/

import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import SVG from "react-inlinesvg";
import {NavLink, withRouter} from 'react-router-dom';

import {PACKAGES, PRICEPERDAY} from "../data";

import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import {PriceFormat} from '../components/PriceFormat';

import sprinticon from "../assets/images/icons/sprint.svg";
import bon from "../assets/images/bon.png";
import finished from "../assets/images/finished.png";
import arrowlefticon from "../assets/images/icons/arrow-left.svg";
import checkitem from "../assets/images/icons/checkitem.svg";
import error from "../assets/images/icons/error.svg";

import mailbg from "../assets/images/mail/bg.png";
import mailcompleted from "../assets/images/mail/completed.png";
import maillogo from "../assets/images/mail/logo.png";
import maillogopowered from "../assets/images/mail/logo_powered.png";
import maildateicon from "../assets/images/icons/date.png";
import mailvideoicon from "../assets/images/icons/video.png";
import mailhosticon from "../assets/images/icons/host.png";
import mailpeopleicon from "../assets/images/icons/respondents.png";
import mailcateringicon from "../assets/images/icons/catering.png";
import googlemaps from "../assets/images/mail/uxlab_maps.png";

const emailFrom = "hallo@concept7.nl";
const nameFrom = "Ux lab";
const emailCC = "hallo@concept7.nl";

class Details extends Layout {
    
    constructor(props) {
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactBTW: '',
            contactSubject: 'Ux lab reservering',
            contactPhone: '',
            contactCompany: '',
            successMsg: '',
            submitsuccess: false,
            submitfailed: false,
            OPTIONS: this.props.cookies.get('OPTIONS') || this.props.selectedOptions.OPTIONS,
            PROFESSIONALS: this.props.cookies.get('PROFESSIONALS') || this.props.selectedProfessionals.PROFESSIONALS,
        };
        
        this.headerTitle = "Reservering";
        
        this._handleSubmit = this._handleSubmit.bind(this);
        this._handleChangeName = this._handleChangeName.bind(this);
        this._handleChangeEmail = this._handleChangeEmail.bind(this);
        this._handleChangeBTW = this._handleChangeBTW.bind(this);
        this._handleChangePhone = this._handleChangePhone.bind(this);
        this._handleChangeCompany = this._handleChangeCompany.bind(this);
    }
    
    _handleChangeName(e) {
        this.setState({
            contactName: e.target.value,
        });
    }
    
    _handleChangeEmail(e) {
        this.setState({
            contactEmail: e.target.value,
        });
    }
    
    _handleChangeBTW(e) {
        this.setState({
            contactBTW: e.target.value,
        });
    }
    
    _handleChangePhone(e) {
        this.setState({
            contactPhone: e.target.value
        });
    }
    
    _handleChangeCompany(e) {
        this.setState({
            contactCompany: e.target.value
        });
    }
    
    
    // Handle form onSubmit
    _handleSubmit(e) {
        // Prevent form default action "load onSubmit" to be triggered
        e.preventDefault();
        
        // Perform an asynchronous HTTP (Ajax) request.
        $.ajax({
            // A string containing the URL to which the request is sent. If not production environment, send request to './getMail'
            url: "/php/sendgridmailer.php",
            // POST request
            type: 'POST',
            // Submit content in contactEmail and contactMessage state
            data: {
                'img_bg': mailbg,
                'img_logo': maillogo,
                'img_logopowered': maillogopowered,
                'img_completed': mailcompleted,
                'img_maps': googlemaps,
                'data_dates': this.renderDates(),
                'data_package': this.renderPackage(),
                'data_extra_days': this.renderExtraDays(),
                'data_av': this.renderAV(),
                'data_lunch': this.renderLunch(),
                'data_host': this.availabilityItem(mailhosticon, 'Ontvangst en host', false),
                'data_respondents': `<tr><td style="width: 10%; text-align: center"><img src="${mailpeopleicon}" alt="icon" style="width: 20px;"></td><td style="color: #DADADA">Respondenten werven</td><td style="width: 20%;"><p style="font-family: Georgia, Times, Times New Roman, serif; font-weight: 600; text-align: right; color: #DADADA">op aanvraag</p></td></tr>`,
                'data_profs': this.renderProfs(),
                'data_options': this.renderOptions(),
                'data_total': this.renderTotal(),
                'tips_pdf': this.renderTipsPdf(),
                'form_from': emailFrom,
                'form_from_name': nameFrom,
                'form_cc': emailCC,
                'form_name': this.state.contactName,
                'form_email': this.state.contactEmail,
                'form_btw': this.state.contactBTW ? `BTW: ${this.state.contactBTW}` : null,
                'form_subject': this.state.contactSubject,
                'form_phone': this.state.contactPhone,
                'form_company': this.state.contactCompany ? `Bedrijfsnaam: ${this.state.contactCompany}` : null
            },
            // If success..
            success: function (data) {
                this.setState({
                    successMsg: '<h1>Je aanvraag is binnen!</h1>'
                });
                $('#details__form').slideUp();
                this.setState({
                    submitsuccess: true
                });
                $('#details__form').slideUp();
            }.bind(this),
            // If fail/error..
            error: function (xhr, status, err) {
                console.log(xhr, status);
                console.log(err);
                this.setState({
                    contactMessage: <h1>Oops! Er is iets fout gegaan, neem telefonisch contact op met ons.</h1>,
                });
                this.setState({
                    submitfailed: true
                });
                $('#details__form').slideUp();
            }.bind(this)
        });
    }
    
    availabilityItem(icon, text, price, style) {
        const styleimg = style ? `"width: 20px; ${style}"` : `"width: 20px;"`;
        return (
            `<tr><td style="width: 10%; text-align: center"><img src="${icon}" alt="icon" style=${styleimg}></td><td>${text}</td><td style="width: 20%;"><p style="font-family: Georgia, Times, Times New Roman, serif; font-weight: 600; text-align: right; ${!price ? 'color: #54D69E;' : ''} ">${price ? price : "inclusief"}</p></td></tr>`
        )
    }
    
    renderPackage() {
        const {selectedPackage, cookies} = this.props;
        const PACKAGE = PACKAGES.reduce((result, item) => {
            if (item.title === (cookies.get('selectedPackageTitle') || selectedPackage.title)) {
                result = item
            }
            return result
        }, null);
        return (
            this.availabilityItem(PACKAGE.iconmail, `${PACKAGE.title} (${PACKAGE.days}${PACKAGE.days_suffix})`, PriceFormat(PACKAGE.price))
        )
    }
    
    renderExtraDays() {
        const {selectedDate, selectedPackage, cookies} = this.props;
        const EXTRADAYS = cookies.get('totalDays') ?
            (cookies.get('totalDays') - (cookies.get('selectedPackageDays') || selectedPackage.days) > 0 ?
                (cookies.get('totalDays') - (cookies.get('selectedPackageDays') || selectedPackage.days)) * PRICEPERDAY :
                null) :
            (selectedDate.total - selectedPackage.days > 0 ? (selectedDate.total - selectedPackage.days) * PRICEPERDAY : null);
        return (
            EXTRADAYS ? this.availabilityItem(maildateicon, `+${cookies.get('totalDays') ? cookies.get('totalDays') - (cookies.get('selectedPackageDays') || selectedPackage.days) : selectedDate.total - selectedPackage.days} ${(cookies.get('totalDays') - (cookies.get('selectedPackageDays')) || selectedPackage.days) || (selectedDate.total - selectedPackage.days) > 1 ? 'extra dagen' : 'extra dag'}`, PriceFormat(EXTRADAYS)) : null
        )
    }

    renderTipsPdf() {
        if(this.state.OPTIONS[1].selected === true) { // Check if diner selected
            return process.env.PUBLIC_URL+'/documents/uxlab-praktisch-tips.pdf';
        } else {
            return process.env.PUBLIC_URL+'/documents/uxlab-praktisch-eten-tips.pdf';
        }
    }

    renderAV() {
        const {selectedPackage, cookies} = this.props;
        const PACKAGE = PACKAGES.reduce((result, item) => {
            if (item.title === (cookies.get('selectedPackageTitle') || selectedPackage.title)) {
                result = item
            }
            return result
        }, null);
        return (
            Object.keys(PACKAGE.list).find(key => PACKAGE.list['A/V recording'] === true) ?
                this.availabilityItem(mailvideoicon, 'A/V Opnames & streaming', false) : null
        )
    }
    
    renderLunch() {
        const {selectedPackage, cookies} = this.props;
        const PACKAGE = PACKAGES.reduce((result, item) => {
            if (item.title === (cookies.get('selectedPackageTitle') || selectedPackage.title)) {
                result = item
            }
            return result
        }, null);
        return (
            Object.keys(PACKAGE.list).find(key => PACKAGE.list['Catering'] === true) ?
                this.availabilityItem(mailcateringicon, 'Lunch & catering', false) : null
        )
    }
    
    renderProfs() {
        const {PROFESSIONALS} = this.state;
        return (
            `<table style="border-collapse:collapse;border-spacing:0;padding:0;text-align:left;vertical-align:top;width:100%">${PROFESSIONALS.filter(item => (item.selected !== false)).map((item) => this.availabilityItem(item.img, item.title, PriceFormat(item.totalprice), 'border-radius: 50%;')).join('')}</table>`
        )
    }
    
    renderOptions() {
        const {OPTIONS} = this.state;
        return (
            `<table style="border-collapse:collapse;border-spacing:0;padding:0;text-align:left;vertical-align:top;width:100%">${OPTIONS.filter(item => (item.selected !== false)).map((item) => this.availabilityItem(item.img, item.title, PriceFormat(item.totalprice), 'border-radius: 50%;')).join('')}</table>`
        )
    }
    
    renderTotal() {
        const {selectedDate, selectedPackage, cookies, optionsPrice} = this.props;
        const PACKAGE = PACKAGES.reduce((result, item) => {
            if (item.title === (cookies.get('selectedPackageTitle') || selectedPackage.title)) {
                result = item
            }
            return result
        }, null);
        const EXTRADAYS = cookies.get('totalDays') ?
            (cookies.get('totalDays') - (cookies.get('selectedPackageDays') || selectedPackage.days) > 0 ?
                (cookies.get('totalDays') - (cookies.get('selectedPackageDays') || selectedPackage.days)) * PRICEPERDAY + 200 :
                null) :
            (selectedDate.total - selectedPackage.days > 0 ? (selectedDate.total - selectedPackage.days) * PRICEPERDAY + 200 : null);
        return (
            PriceFormat(PACKAGE.price + optionsPrice + EXTRADAYS)
        )
    }
    
    renderDates() {
        const {selectedDate, cookies} = this.props;
        return (
            (cookies.get('selectedStartDate') && cookies.get('selectedEndDate')) ?
                (moment(cookies.get('selectedStartDate')).format('DD MMMM') === moment(cookies.get('selectedEndDate')).format('DD MMMM') ?
                    moment(cookies.get('selectedStartDate')).format('DD MMMM') :
                    `${moment(cookies.get('selectedStartDate')).format('DD MMMM')} - ${moment(cookies.get('selectedEndDate')).format('DD MMMM')}`) :
                (moment(selectedDate.startDate).format('DD MMMM') === moment(selectedDate.endDate).format('DD MMMM') ?
                    moment(selectedDate.startDate).format('DD MMMM') :
                    `${moment(selectedDate.startDate).format('DD MMMM')} - ${moment(selectedDate.endDate).format('DD MMMM')}`)
        )
    }
    
    renderSidebar() {
        const {selectedPackage, cookies, totalPrice} = this.props;
        return (
            <Sidebar>
                <h2 className="sidebar__title">Ux lab Groningen</h2>
                <div className="sidebar__content">
                    <div className="sidebar__content-item-wrapper">
                        <div className="sidebar__content-item">
                            <div className="sidebar__content-item-title-wrapper">
                                <div className="sidebar__content-item-icon">
                                    <SVG
                                        className='sidebar__content-item-icon-svg'
                                        src={sprinticon}
                                        uniquifyIDs
                                        cacheGetRequests
                                    />
                                </div>
                                <div className="sidebar__content-item-title">
                                    Ux lab <br/>
                                    {cookies.get('selectedPackageTitle') ? (cookies.get('selectedPackageTitle') === 'Ux lab standaard' ? 'Standaard' : cookies.get('selectedPackageTitle')) : (selectedPackage.title === 'UX Lab standaard' ? 'Standaard' : selectedPackage.title)}
                                </div>
                            </div>
                            <div className="sidebar__content-item-date">
                                {this.renderDates()}
                            </div>
                        </div>
                        
                        {(!this.state.submitsuccess && !this.state.submitfailed) &&
                        <div className="sidebar__content-item">
                            <div className="availability__summary__total">
                                <span
                                    className="availability__summary__total-label">Totaal</span> {PriceFormat(cookies.get('TOTALPRICE') || totalPrice)}
                            </div>
                            <div className="availability__summary__text">
                                Alle prijzen zijn exclusief btw.
                            </div>
                        </div>}
                        
                        {this.state.submitsuccess && <div className="sidebar__content-item">
                            <div className="sidebar__content-item-title-wrapper">
                                <div className="sidebar__content-item-icon">
                                    <SVG
                                        className='sidebar__content-item-icon-svg'
                                        src={checkitem}
                                        uniquifyIDs
                                        cacheGetRequests
                                    />
                                </div>
                                <div className="sidebar__content-item-text">
                                    Je vindt je aanvraagbevestiging in je mailbox
                                </div>
                            </div>
                        </div>}
                        
                        {this.state.submitfailed && <div className="sidebar__content-item">
                            <div className="sidebar__content-item-title-wrapper">
                                <div className="sidebar__content-item-icon">
                                    <SVG
                                        className='sidebar__content-item-icon-svg'
                                        src={error}
                                        uniquifyIDs
                                        cacheGetRequests
                                    />
                                </div>
                                <div className="sidebar__content-item-text">
                                    Helaas! Je aanvraag is niet gelukt!
                                </div>
                            </div>
                        </div>}
                    
                    </div>
                    <div className="sidebar__content-end" style={{backgroundImage: `url('${bon}')`}}/>
                </div>
            </Sidebar>
        );
    }
    
    renderPageContent() {
        return (
            <div className="details">
                <div className="details__container">
                    {(!this.state.submitsuccess && !this.state.submitfailed) &&
                    <div className="details__form content-container" id="details__form">
                        <div className="details__content__block">
                            <div className="details__content__title">Je gegevens</div>
                            <form className="form" onSubmit={this._handleSubmit}>
                                
                                <fieldset>
                                    <label className="required" htmlFor="formName">Naam</label>
                                    <input id="formName" type="text" placeholder="Naam" name="formName"
                                           value={this.state.contactName} onChange={this._handleChangeName} required/>
                                </fieldset>
                                
                                <fieldset>
                                    <label htmlFor="formCompany">Bedrijfsnaam</label>
                                    <input id="formCompany" type="text" placeholder="Bedrijfsnaam" name="formCompany"
                                           value={this.state.contactCompany} onChange={this._handleChangeCompany}/>
                                </fieldset>
                                
                                <fieldset>
                                    <label htmlFor="formBTW">BTW nummer</label>
                                    <input id="formBTW" type="text" placeholder="BTW nummer" name="formBTW"
                                           value={this.state.contactBTW} onChange={this._handleChangeBTW}/>
                                </fieldset>
                                
                                <fieldset>
                                    <label className="required" htmlFor="formEmail">E-mailadres</label>
                                    <input type="email" placeholder="E-mailadres" id="formEmail" name="formEmail"
                                           value={this.state.contactEmail} onChange={this._handleChangeEmail} required/>
                                </fieldset>
                                
                                <fieldset>
                                    <label className="required" htmlFor="formPhone">Telefoonnummer</label>
                                    <input type="text" placeholder="Telefoonnummer" id="formPhone" name="formPhone"
                                           value={this.state.contactPhone} onChange={this._handleChangePhone} required/>
                                </fieldset>
                                
                                <fieldset>
                                    <label className="required">Voorwaarden</label>
                                    <div className="details__terms">
                                        <label><input type="checkbox" required/>Ik ga akkoord met de <NavLink
                                            to="/algemene-voorwaarden">algemene voorwaarden</NavLink></label>
                                    </div>
                                </fieldset>
                                <fieldset className="btn-wrapper">
                                    <div className="btn-back" onClick={this.props.history.goBack}>
                                        <SVG
                                            className='btn-back-icon'
                                            src={arrowlefticon}
                                            uniquifyIDs
                                            cacheGetRequests
                                        />
                                        Terug
                                    </div>
                                    <div className="details__form-btn">
                                        <button type="submit" id="btn-submit" className="btn btn-pink">Reserveren
                                        </button>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>}
                    
                    {this.state.submitsuccess &&
                    <div className="details__content__message details__content__message-success">
                        <div className="details__content__message-title">
                            Je aanvraag is binnen!
                        </div>
                        <p>
                            Wij nemen zo snel mogelijk contact met je op voor de bevestiging van je reservering.
                        </p>
                    </div>}
                    {this.state.submitfailed &&
                    <div className="details__content__message details__content__message-fail">
                        <div className="details__content__message-title">
                            Helaas! Je aanvraag is niet gelukt!
                        </div>
                        <p>
                            Oei! Daar ging wat fout. Het versturen van de aanvraag is niet gelukt, zou je het opnieuw
                            willen proberen?
                        </p>
                    </div>
                    }
                    
                    {(this.state.submitsuccess || this.state.submitfailed) && <div className="details__content__tips">
                        <div className="details__content__tips-container"
                             style={{backgroundImage: `url('${finished}')`}}>
                            <div className="details__content__title">
                                Voel je thuis
                            </div>
                            <p>
                                De beste tips voor eten, drinken en overnachten in Groningen,<br/>
                                direct van onze eigen locals!
                            </p>
                            <a href={this.renderTipsPdf()} rel="noopener noreferrer" target="_blank" className="btn btn-pink">
                                Download onze tips (PDF)
                            </a>
                        </div>
                    </div>}
                
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    selectedPackage: state.selectedPackage,
    selectedDate: state.selectedDate,
    selectedOptions: state.selectedOptions,
    selectedProfessionals: state.selectedProfessionals,
    optionsPrice: state.optionsPrice,
    totalPrice: state.totalPrice,
    cookies: ownProps.cookies,
});

export default connect(mapStateToProps)(withRouter(Details));