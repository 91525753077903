import {PACKAGES} from "../data";

export default function (state = PACKAGES[0].price, action) {
				switch (action.type) {
								case "TOTAL_PRICE": {
												return action.payload;
								}
								default:
												return state;
				}
}