import {OPTIONS} from "../data";

export default function (state = {OPTIONS}, action) {
				switch (action.type) {
								case "OPTIONS_SELECTED": {
												return action.payload;
								}
								default:
												return state;
				}
}