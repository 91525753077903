import {PACKAGES} from "../data";

export default function (state = {title: PACKAGES[0].title, days: PACKAGES[0].days, price: PACKAGES[0].price}, action) {
				switch (action.type) {
								case "PACKAGE_SELECTED": {
												return action.payload;
								}
								default:
												return state;
				}
}